<template>
    <div class="main-wrapper">
        <div class="container-fluid login-wrapper">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12 px-0">
                    <div class="sing_bg custom_sign">
                        <div class="signup-background">
                            <div class="Mobile-logo d-lg-none mb-4 text-center">
                                <img src="/snapp-associates/logo.png" class="img-fluid" alt="img">
                            </div>
                            <div class="sigin-left">
                                <div class="login-content">
                                    <h3>Sign Up Now </h3>
                                    <p>Begin your restaurant and hospitality industry training today!</p>
                                </div>
                                <div class="signup-bttns">
                                    <router-link to="/user_register">
                                        <div class="signup-box mr-3">
                                            <div class="sign-img">
                                                <img src="/snapp-associates/single-user.png" class="indiviual-img" alt="Individual User">
                                            </div>
                                            <p>Individual Sign Up</p>
                                        </div>
                                    </router-link>
                                    <router-link to="/register">
                                        <div class="signup-box camp_bx">
                                            <div class="sign-img">
                                                <img src="/snapp-associates/company-user.png" class="company-img" alt="Company User">
                                            </div>
                                            <p>Company Sign Up</p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div class="donthaveact d-lg-none">
                                Already have an account?
                                <router-link to="/snapp-associates">
                                    Login
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Signup"
}
</script>
<style scoped src="@/snapp-associates/partials/master.css"></style>
<style scoped src="@/snapp-associates/partials/responsive.css"></style>